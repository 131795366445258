<template>
    <div>
      <section class="study">
        <h1 style="font-size: 13px" class="bold my-3">Kết quả test đầu vào</h1>
        <div class="table-responsive w-100">
          <table class="table table-bordered">
            <thead class="table-secondary">
            <tr align-items class=" " style="font-size: 13px">
              <th>STT</th>
              <th>Trung tâm test</th>
              <th>Trạng thái ca test</th>
              <th>Hình thức test</th>
              <th>Overall</th>
              <th>Khóa học gợi ý</th>
              <th>Thời gian test</th>
              <th class="text-center">Hành động</th>
            </tr>
            </thead>
            <tbody v-if="testInputResults.length == 0">
            <tr class="text-center">
              <td colspan="8">Không có dữ liệu</td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr
                style="font-size: 13px"
                v-for="(item, index) of testInputResults"
                :key="index"
            >
            <td>{{ index + 1 }}</td>
            <td>{{ item.center_name }}</td>
            <td>{{ item.status_name}}</td>
            <td>{{ item.type_name }}</td>
            <td>{{ item.overall }}</td>
            <td>{{ item.course_suggest }}</td>
            <td>{{ item.time_test}}</td>
            <td class="text-center">
                <button title="Xem chi tiết" class="rounded btn-outline-info" @click="showModalDetail(item.customer_id)">
                    <i class="fa fa-eye info"></i>
                </button>
            </td>
            </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  
  export default {
    name: "entry-test-result",
    components: {},
    props: {
     testInputResults: {
         type: Array,
         default: () => {
             return []
         }
    }
    },
    data() {
      return {
      };
    },
    mounted() {

    },
    methods: {
        showModalDetail(customer_id) {
            const routeData = this.$router.resolve({name: 'customer-test-input-schedule', params: {id: customer_id}});
            window.open(routeData.href, '_blank');
        },
    },
  };
  </script>
  
<style scoped>
</style>
  