<template>
  <div class="row">
    <section class="col-7 row " >
     <div class="col-6 mb-4">
       <p class="mb-2">Họ và tên:</p>
       <el-input :value="detailContract?.customer?.name" placeholder="" disabled />
     </div>
      <div class="col-6 mb-4">
        <p class="mb-2">Email:</p>
        <el-input :value="detailContract?.customer?.email" placeholder="" disabled/>
      </div>
      <div class="col-6 mb-4">
        <p class="mb-2">Số điện thoại:</p>
        <el-input :value="detailContract?.customer?.phone" placeholder="" disabled/>
      </div>
      <div class="col-6 mb-4">
        <p class="mb-2">Ngày tháng năm sinh: </p>
        <el-input :value="detailContract?.customer?.ngay_sinh" placeholder="" disabled/>
      </div>
      <div class="col-12 mb-4" v-for="(course,key) in detailContract?.customer?.khoa_hoc_muon_dang_ky " :key="key">
        <p class="mb-2">Khóa học: </p>
        <el-input :value="course" placeholder="" disabled/>
      </div>
      <div class="col-6 mb-4">
        <p class="mb-2">Trung tâm: </p>
        <el-input :value="detailContract?.center?.name" placeholder="" disabled/>
      </div>
      <div class="col-6 mb-4">
        <p class="mb-2">Chi nhánh: </p>
        <el-input :value="detailContract?.branch" placeholder="" disabled/>
      </div>
      <div class="col-6 mb-4">
        <p class="mb-2">Hình thức đóng học phí: </p>
        <el-input :value="detailContract?.tuitionCompletionForm" placeholder="" disabled/>
      </div>
    </section>
    <section class="col-5">
     <div class="card container p-4 " style="background-color :#EFF2F8;">
       <div >
         <h1 style="font-size: 16px;font-weight: bold">Khóa học đã chọn</h1>
         <div class="d-flex justify-content-between align-items-center mb-2" style="background-color: #f5f7fa;  padding: 8px; cursor: pointer" @click="is_detail_course = !is_detail_course" >
           <p style="margin-bottom: 0px">Chi tiết khóa học</p>
           <i class="el-icon-caret-bottom"></i>
         </div>
         <div style="overflow: auto; width: 100%">
           <table class="table " v-if="is_detail_course" style="width: max-content">
             <tbody >
             <tr v-for="(course_infor,key) in detailContract?.student_register_courses" :key="key" >
               <td style="width: fit-content">{{ course_infor.course.name }}</td>
               <td style="width: fit-content">{{formatMoney(course_infor?.course?.fee)}}</td>
               <td style="width: fit-content">{{ course_infor.course_sale}} %</td>
               <td style="width: fit-content">{{formatMoney(parseInt(course_infor.course_fee) * (1 - parseInt(course_infor.course_sale)/100))}}</td>
             </tr>
             </tbody>
           </table>
         </div>

       </div>
       <div class="d-flex justify-content-between">
         <p style="word-break: keep-all">tổng giá khóa học</p>
         <p>{{formatMoney(totalCourseRegisterFee)}}</p>
       </div>

       <h1 style="font-size: 16px;font-weight: bold">Chi tiết thanh toán</h1>
       <div class="d-flex justify-content-between align-items-center mb-2" style="background-color: #f5f7fa;  padding: 8px; cursor: pointer" @click="is_promotion_details = !is_promotion_details" >
         <p style="margin-bottom: 0px">Chi tiết khuyến mãi</p>
         <i class="el-icon-caret-bottom"></i>
       </div>
       <div style="overflow: auto; width: 100%">
         <table class="table " v-if="is_promotion_details"  style="width: max-content">
           <tbody>
           <tr v-if="promotionForContract" >
             <td>Giảm giá mã Km:</td>
             <td >{{promotionForContract.name}}</td>
             <td>{{formatMoney(promotionForContract.value)}}</td>
           </tr>
           <tr v-for="(voucher,index) in vouchersForContract" :key="index" >
             <td>Voucher {{++index}}:</td>
             <td>{{voucher.name}}</td>
             <td>
               <strong class="text-danger" v-if="voucher.unit === 1 ">{{formatMoney(voucher.value)}} ₫</strong>
               <strong class="text-danger" v-else>{{ voucher.value }}%</strong></td>
           </tr>
           </tbody>
         </table>
       </div>
       <div class="d-flex justify-content-between">
         <p style="word-break: keep-all">Tổng khuyến mại:</p>
         <p style="word-break: keep-all"> {{formatMoney(totalDiscount)}}</p>
       </div>
       <hr>
       <div class="d-flex justify-content-between">
         <p style="word-break: keep-all">Tổng giá trị hợp đồng:</p>
         <p style="word-break: keep-all">{{formatMoney(totalCourseRegisterFee - totalDiscount)}}</p>
       </div>
       <div class="d-flex justify-content-between">
         <p style="word-break: keep-all">Đã thanh toán:</p>
         <p style="word-break: keep-all">{{formatMoney(detailContract.total_paid)}}</p>
       </div>
       <div class="d-flex justify-content-between">
         <p style="word-break: keep-all">Còn nợ:</p>
         <p style="word-break: keep-all">{{formatMoney((totalCourseRegisterFee - totalDiscount) - detailContract.total_paid)}}</p>
       </div>

     </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "modal-detail-contract",
  props:{
    detailContract:{
      type: Object,
      default(){
        return {}
      }
    },
    flagContract:{
      type: Boolean,
      default() {
        return true;
      }
    }
  },
  mounted() {
  },
  data(){
    return {
      is_detail_course : false,
      is_promotion_details : false,
    }
  },
  methods:{
    formatMoney(value){
      value = value.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
      return value
    }
  },
  computed:{
    promotionForContract(){
      return this.detailContract?.vouchers?.filter(item => item.type !== 0)?.shift();
    },
    vouchersForContract(){
      return this.detailContract.vouchers.filter(item => item.type === 0);
    },
    totalDiscount(){
      let valuePromotion = this.promotionForContract ? this.promotionForContract.value : 0;
      return valuePromotion + this.totalValueOfVoucher + (this.totalCourseRegisterFee * this.totalDiscountByVoucher/100)
    },
    totalValueOfVoucher(){
     return _.reduce(this.vouchersForContract, function (result,value,key){
       if (value.unit === 1){
         result += value.value;
       }
       return result;
     },0);
    },
    totalCourseRegisterFee(){
     return this.detailContract?.student_register_courses?.reduce((result, item) => { return result + (item.course_fee *(100 - item.course_sale)/ 100)}, 0);
    },
    totalDiscountByVoucher(){
      return _.reduce(this.vouchersForContract, function (result,value,key){
        if (value.unit === 0){
          result += value.value;
        }
        return result;
      },0);
    }
  }
}
</script>

<style scoped>
.el-collapse-head-2406{
  height: 10px !important;
}
</style>