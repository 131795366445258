import { render, staticRenderFns } from "./detail-customer.vue?vue&type=template&id=38d79a45&scoped=true&"
import script from "./detail-customer.vue?vue&type=script&lang=js&"
export * from "./detail-customer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38d79a45",
  null
  
)

export default component.exports