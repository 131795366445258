<template>
<div>
  <el-collapse>
    <el-collapse-item
        v-for="(item, index) in policy"
        :key=index
        :title="item.name ?? ''"
        :name="item.catalog_id ?? ''"
        style="word-break: keep-all"
    >
      <div class="table-ck" v-html="item.content" style="word-break: keep-all"></div>
    </el-collapse-item>
  </el-collapse>
</div>
</template>

<script>
export default {
  name: "modal-policy-course",
  props: {
    policy: {
      default: {}
    }
  },
}
</script>

<style scoped>

</style>