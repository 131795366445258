<template>
  <div>
    <div class="d-flex justify-content-evenly">
      <div data-v-dc9f3a66="" class="col-sm-4" style="margin-left: -1px">
        <label data-v-dc9f3a66="" class="form-label col-form-label"
          >Mã khách hàng
        </label>
        
        <div data-v-dc9f3a66="" class="w-100 el-input is-disabled">
          <!----><input
            type="text"
            disabled="disabled"
            autocomplete="off"
            filterable=""
            class="el-input__inner"
            fdprocessedid="c5a4o"
            :value="user.id"
          />
        </div>
        
        <label data-v-dc9f3a66="" class="form-label col-form-label"
          >Số điện thoại liên hệ
        </label>
        <div data-v-dc9f3a66="" class="w-100 el-input is-disabled">
          <!----><input
            type="text"
            disabled="disabled"
            autocomplete="off"
            filterable=""
            :value="user.phone"
            class="el-input__inner"
            fdprocessedid="sbxprn"
          />
        </div>
        <label data-v-dc9f3a66="" class="form-label col-form-label"
          >Ngày sinh
        </label>
        <div data-v-dc9f3a66="" class="w-100 el-input is-disabled">
          <!----><input
            type="text"
            disabled="disabled"
            autocomplete="off"
            filterable=""
            :value="user?.profile?.birthday_format"
            class="el-input__inner"
            fdprocessedid="sbxprn"
          />
        </div>
      </div>
      <div data-v-dc9f3a66="" class="col-sm-4" style="margin-left: 1px">
        <label data-v-dc9f3a66="" class="form-label col-form-label"
          >Họ và tên
        </label>
        <div data-v-dc9f3a66="" class="w-100 el-input is-disabled">
          <!----><input
            type="text"
            disabled="disabled"
            autocomplete="off"
            filterable=""
            :value="user.name"
            class="el-input__inner"
            fdprocessedid="sbxprn"
          />
        </div>
        <label data-v-dc9f3a66="" class="form-label col-form-label"
          >Email
        </label>
        <div data-v-dc9f3a66="" class="w-100 el-input is-disabled">
          <input
            type="text"
            disabled="disabled"
            autocomplete="off"
            filterable=""
            :value="user.email"
            class="el-input__inner"
            fdprocessedid="sbxprn"
          />
        </div>
        <label data-v-dc9f3a66="" class="form-label col-form-label"
          >Địa chỉ
        </label>
        <div data-v-dc9f3a66="" class="w-100 el-input is-disabled">
          <input
            type="text"
            disabled="disabled"
            autocomplete="off"
            filterable=""
            :value="user?.profile?.address"
            class="el-input__inner"
            fdprocessedid="sbxprn"
          />
        </div>
      </div>
      <div data-v-dc9f3a66="" class="col-md-4" style="margin-left: 1px">
        <label data-v-dc9f3a66="" class="form-label col-form-label"
          >Số điện thoại đăng ký
        </label>
        <div data-v-dc9f3a66="" class="w-100 el-input is-disabled">
          <input
            type="text"
            disabled="disabled"
            autocomplete="off"
            filterable=""
            class="el-input__inner"
            fdprocessedid="sbxprn"
            :value="user.phone"
          />
        </div>
        <label data-v-dc9f3a66="" class="form-label col-form-label"
          >Giới tính
        </label>
        <div data-v-dc9f3a66="" class="w-100 el-input is-disabled">
          <input
            type="text"
            disabled="disabled"
            autocomplete="off"
            filterable=""
            :value="user?.profile?.gender"
            class="el-input__inner"
            fdprocessedid="sbxprn"
          />
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-around" style="margin-top: 3%" v-if="userParent">
      <div data-v-dc9f3a66="" class="col-md-4" style="margin-left: 1px">
        <div class="container">
          <div class="row">
            <h1 class="" style="font-size: 15px; font-weight: bold">
              Thông tin người giám hộ
            </h1>
          </div>
        </div>
        <label data-v-dc9f3a66="" class="form-label col-form-label"
          >Họ và tên
        </label>
        <div data-v-dc9f3a66="" class="w-100 el-input is-disabled">
         <input
            type="text"
            disabled="disabled"
            autocomplete="off"
            filterable=""
            :value="userParent.name"
            class="el-input__inner"
            fdprocessedid="sbxprn"
          />
        </div>
        <label data-v-dc9f3a66="" class="form-label col-form-label"
          >Số điện thoại khác
        </label>
        <div data-v-dc9f3a66="" class="w-100 el-input is-disabled">
         <input
            type="text"
            disabled="disabled"
            autocomplete="off"
            filterable=""
            :value="userParent.phone_other"
            placeholder="Không có"
            class="el-input__inner"
            fdprocessedid="sbxprn"
          />
        </div>
      </div>
      <div
        data-v-dc9f3a66=""
        class="col-md-4"
        style="margin-left: 1px; margin-top: 2%"
      >
        <label data-v-dc9f3a66="" class="form-label col-form-label"
          >Số điện thoại
        </label>
        <div data-v-dc9f3a66="" class="w-100 el-input is-disabled">
          <!----><input
            type="text"
            disabled="disabled"
            autocomplete="off"
            :value="userParent.phone"
            class="el-input__inner"
            fdprocessedid="sbxprn"
          />
        </div>
      </div>
      <div
        data-v-dc9f3a66=""
        class="col-md-4"
        style="margin-left: 1px; margin-top: 2%"
      >
        <label data-v-dc9f3a66="" class="form-label col-form-label"
          >Mối quan hệ với khách hàng
        </label>
        <div data-v-dc9f3a66="" class="w-100 el-input is-disabled">
          <!----><input
            type="text"
            disabled="disabled"
            autocomplete="off"
            filterable=""
            :value="userParent?.type === 'khac' ? userParent?.value_other_type : userParent?.type === 'bo' ? 'Bố' : 'Mẹ'"
            class="el-input__inner"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {GET_USER_PARENT} from "@/core/services/store/customer/customer.module";

export default {
  name: "basic-information",
  props:{
    user:{
      type: Object,
      default(){
        return {}
      }
    }
  },
  data(){
    return {
     userParent : []
    }
  },
  mounted() {
    this.getUserParent()
  },
  methods:{
    getUserParent(){
      let idUser = this.$route.params.id
      this.$store.dispatch(GET_USER_PARENT,idUser).then((data)=>{
        if(!data.data){
          this.userParent = null
          return
        }
        this.userParent = {...data?.data?.user?.user_application,type:data?.data?.type,value_other_type:data?.data?.value_other_type,phone:data?.data?.parent?.phone }
      })
    }
  }
};
</script>

<style scoped>
</style>
