<template>
  <div>
    <section class="study">
      <h1 style="font-size: 13px" class="bold my-3">Thông tin tổng quan</h1>
      <div class="table-responsive w-100">
        <table class="table table-bordered">
          <thead class="table-secondary">
          <tr class="" style="font-size: 13px">
            <th>Khóa học</th>
            <th>Lớp học</th>
            <th>Lộ trình học</th>
            <th>Số buổi đi học</th>
            <th>Số buổi làm BTVN</th>
            <th>Kết quả đầu ra</th>
            <th>Thời gian bắt đầu học</th>
            <th class="text-center">Hành động</th>
          </tr>
          </thead>
          <tbody v-if="listStudy.length === 0">
          <tr class="text-center">
            <td colspan="8">Không có dữ liệu</td>
          </tr>
          </tbody>
          <tbody v-else>
          <tr
              style="font-size: 13px"
              v-for="(item, index) of listStudy"
              :key="index"
          >
            <td>{{ item.course && item.course.name }}</td>
            <td>{{ item.class ? item.class.name : '(Chưa có thông tin)' }}</td>
            <td>{{ item.schedule }}</td>
            <td>{{ item.schedule_number }}</td>
            <td>{{ item.homework }}</td>
            <td>
              <span :class="renderOutput(item.output).classText">{{renderOutput(item.output).name}}</span>
            </td>
            <td>{{convertTime(`${item?.class?.schedule?.start_date} ${item?.class?.start_date}`)}}</td>
            <td class="text-center">
              <button class="btn btn-info btn-sm btn-icon waves-effect waves-themed" @click="openModal(item)">
                <i class="fa fa-eye"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </section>
    <el-dialog
        title="Tình hình học tập"
        :visible.sync="isOpen" width="60%"
    >
      <div>
        <div class="class-title ml-7 d-flex">
          <p style="min-width: 75px">Lớp học:</p> <span>{{ classroomShow && classroomShow.name }}</span>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-3 form-group">
              <label>Lộ trình học:</label>
              <input type="text" class="form-control" :value="studentRegisterCourse && studentRegisterCourse.schedule" disabled>
            </div>
            <div class="col-md-3 form-group">
              <label>Số buổi đi học:</label>
              <input type="text" class="form-control" :value="studentRegisterCourse && studentRegisterCourse.schedule_number" disabled>
            </div>
            <div class="col-md-3 form-group">
              <label>Số buổi làm bài tập:</label>
              <input type="text" class="form-control" :value="studentRegisterCourse && studentRegisterCourse.homework" disabled>
            </div>
            <div class="col-md-3 form-group">
              <label>Kết quả đầu ra:</label>
              <input type="text" class="form-control" :value="renderOutput(output)" disabled>
            </div>
          </div>
          <div class="table-responsive overflow-auto">
            <table class="table table-vertical-center table-hover border">
              <thead>
              <tr>
                <th>
                  <span style="word-break: break-word">Buổi học</span>
                </th>
                <th>Thời gian học</th>
                <th>Điểm danh</th>
                <th>HV đánh giá</th>
                <th>Bài tập về nhà</th>
                <th>GV nhận xét</th>
              </tr>
              </thead>
              <tbody v-if="loading">
              <tr>
                <td colspan="6" class="text-center">
                  <div class="d-flex justify-content-center w-100">
                    <i class="el-icon-loading"></i>
                  </div>
                </td>
              </tr>
              </tbody>
              <tbody v-else-if="schedules.length > 0">
              <tr v-for="(item, key) in schedules" :key="key">
                <td>
              <span style="word-break: break-word; text-transform: capitalize">
                {{ checkLessonToString(item.lesson, item.type, schedules.length) }}
              </span>
                </td>
                <td>{{item.time}}</td>
                <td>
                  <label
                      v-if="item.attendance"
                      class="px-2 py-1 label-rounded"
                      style="white-space: nowrap"
                      :class="item.attendance === 'Vắng mặt' ? 'bg-red text-white' :
                      item.attendance === 'Có mặt' ? 'bg-green text-white' :
                      item.attendance === 'Có phép' ? 'bg-yellow text-black' :
                      item.attendance === 'Ngừng học' ? 'bg-gray1 text-white' : 'bg-gray2'"
                  >{{item.attendance}}</label>
                </td>
                <td>
                  <el-tooltip v-if="item.studentFeedback" :content="item.studentFeedback" placement="top">
                    <label
                        class="px-2 py-1 label-rounded"
                        style="white-space: nowrap"
                        :class="item.rating > 2 ? 'bg-green text-white' : item.rating <= 2 && item.rating > 0 ? 'bg-red text-white' : 'bg-gray2'"
                    >{{ item.rating + ' sao' }}</label>
                  </el-tooltip>
                  <label
                      v-else-if="item.rating > 0"
                      class="px-2 py-1 label-rounded"
                      style="white-space: nowrap"
                      :class="item.rating > 2 ? 'bg-green text-white' : item.rating <= 2 ? 'bg-red text-white' : 'bg-gray2'"
                  >{{ item.rating + ' sao' }}</label>
                  <label v-else-if="item.attendance" class="px-2 py-1 label-rounded bg-gray2">
                    Không đánh giá
                  </label>
                </td>
                <td>
                  <label
                      v-if="item.homeworkStatus !== ''"
                      class="px-2 py-1 label-rounded"
                      style="white-space: nowrap"
                      :class="item.homeworkStatus === 'Hoàn thành' ? 'bg-green text-white'
                      : item.homeworkStatus === 'Không làm' ? 'bg-gray1 text-white'
                      : item.homeworkStatus === 'Kết quả thấp' ? 'bg-red text-white'
                      : item.homeworkStatus === 'Không có bài tập' ? 'bg-gray2'
                      : item.homeworkStatus === '' ? 'bg-gray2' : ''"
                  >{{ item.homeworkStatus }}</label>
                </td>
                <td>
                  <el-tooltip
                      v-if="checkDisableDetail(item.lesson, item.autoComment, item.commentPhase, item.comments, schedules)"
                      content="Giảng viên chưa nhận xét" placement="top"
                      style="opacity: 0.6"
                  >
                    <button
                        class="btn btn-info btn-sm btn-icon waves-effect waves-themed">
                      <i class="far fa-eye"></i>
                    </button>
                  </el-tooltip>
                  <el-tooltip v-else content="Xem chi tiết" placement="top">
                    <button class="btn btn-info btn-sm btn-icon waves-effect waves-themed" @click="showModalCommentDetail(item)">
                      <i class="far fa-eye info"></i>
                    </button>
                  </el-tooltip>
                </td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td colspan="7" class="event-title no-data text-center">Chưa có dữ liệu</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
        :title="`Nhận xét của giảng viên về buổi ${lesson}`"
        :visible.sync="showCommentDetail"
    >
      <div class="mx-5" >
        <p style="color: black; word-break: break-word">{{ autoComment }}</p>
        <div v-if="commentDetail !== null">
          <h5 style="color: #0a0a0a; margin-bottom: 5px; font-weight: 700">Chi tiết buổi học</h5>
          <div v-for="item of commentDetail" style="margin-bottom: 15px" :key="item.title">
            <div v-if="item.recommendAuto !== '' || item.feedback !== ''">
              <label class="label-detail">
                {{item.title === 1 ? 'Tính chủ động'
                  : item.title === 2 ? 'Độ tập trung' : 'Độ tiếp thu' }}
              </label>
              <p style="color: black; word-break: break-word;" v-if="item?.recommendAuto !== ''">
                {{ item?.recommendAuto }}
              </p>
              <div style="display: flex; align-content: center; word-break: break-word" v-if="item?.feedback !== ''">
                <div class="feedback"></div>
                <p style="margin: auto; word-break: break-word;">{{ item?.feedback }}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="comment_phase">
          <label style="font-weight: 700; color: #0a0a0a">Nhận xét tổng quan giai đoạn {{ comment_phase.start }} - {{ comment_phase.end }}</label>
          <p style="color: #0a0a0a; word-break: break-word">{{ comment_phase?.comment }}</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {CUSTOMER_SCHEDULE_LIST, GET_DETAIL_CUSTOMER_STUDY} from "@/core/services/store/customer/customer.module";
import moment from "moment-timezone";
import {StudyDetail} from "@/views/pages/advise/models/StudyDetail";

export default {
  name: "customer-study",
  data() {
    return {
      id: null,
      listStudy: [],
      isOpen: false,
      studentRegisterCourse: {},
      classroomShow: {},
      flag: false,
      lesson: 0,
      schedules: [],
      loading: true,
      showCommentDetail: false,
      commentDetail: [],
      comment_phase: {},
      autoComment: '',
      output: '',
    };
  },
  mounted() {
    let idUser = this.$route.params.id
    this.getListStudy(idUser);
  },
  methods: {
    getListStudy(id) {
      this.$store.dispatch(GET_DETAIL_CUSTOMER_STUDY, id).then((data) => {
        this.listStudy = data.data;
      });
    },
    openModal(studentRegisterCourse) {
      this.getSchedule(studentRegisterCourse.id);
      this.studentRegisterCourse = studentRegisterCourse;
      this.classroomShow = studentRegisterCourse.class;
      this.isOpen = true;
      this.flag = !this.flag;
    },
    renderOutput(output) {
      switch (output) {
        case 1:
          return  'Pass';
        case 2:
          return 'Fail'
        default:
          return  'Chưa có';
      }
    },
    convertTime(time){
      let timeFormat = moment(time).format('HH:ss DD/MM/YYYY')
        return timeFormat === 'Invalid date'?'': timeFormat;
    },
    showModalCommentDetail (item) {
      this.lesson = item.lesson
      this.commentDetail = item.comments
      this.comment_phase = item.commentPhase
      this.autoComment = item.autoComment
      if (item.comments !== null || item.commentPhase !== null || item.autoComment !== null) {
        this.showCommentDetail = true
      }
    },
    checkLessonToString(item, type, totalLesson) {
      if(item === 1) {
        return 'Buổi khai giảng'
      }
      if(type === 'test') {
        if(item === totalLesson - 1) {
          return 'Final Test'
        } else {
          return 'Mid Term Test'
        }
      }
      if (item === totalLesson) {
        return 'Buổi tổng kết'
      }
      return `buổi ${item}`
    },
    checkDisableDetail(lesson, autoComment, commentPhase, comments, dataLength) {
      return lesson === 1 || lesson === dataLength - 1 || lesson === dataLength ||
          (autoComment === null && commentPhase === null && comments === null)
    },
    getSchedule(id) {
      this.$store.dispatch(CUSTOMER_SCHEDULE_LIST, id).then((res) => {
        this.output = res.data.is_pass
        console.log(res.data.is_pass)
        this.checkDisableDetail()
        this.loading = false
        const result = res.data.result
        this.schedules = result.map(item => new StudyDetail(
            item?.lesson,
            item?.day,
            item?.is_scheduled,
            item?.rating,
            item?.homework_status,
            item?.comments,
            item?.comment_phase,
            item?.type,
            result?.length,
            item?.student_feedback,
            item?.comment
        ));
      }).catch()
    },
  },
};
</script>

<style scoped>
a{
  width: 100px;
  overflow: auto;
}
.view-feedback {
  border: none;
  margin-left: 20px;
  padding: 6px 10px;
}
.view-feedback:hover {
  color: #0BB7AF;
}
.label-rounded {
  border-radius: 5px;
  padding: 3px 6px;
}
.bg-red {
  background: #BF0000;
}
.bg-green {
  background: #128300;
}
.bg-gray1 {
  background: #3D3D3D
}
.bg-yellow {
  background: #FFC700;
}
.bg-gray2 {
  background: #DEDEDE
}
.label-detail {
  background: #FEC803;
  padding: 4px 6px;
  border-radius: 4px;
  color: black;
}
.feedback {
  height: 45px;
  width: 1px;
  margin:0 10px;
  background: #858585;
}
</style>
