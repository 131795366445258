<template>
<div>
  <div class="d-flex mt-4" >
    <div style="width: 15%" class="mr-3">
      <p class="mb-2">Chờ xử lý</p>
      <el-input   placeholder="Please input" clearablec :value="ticketWaiting" disabled/>
    </div>
    <div style="width: 15%" class="mr-3">
      <p class="mb-2">Đã nhập giải pháp</p>
      <el-input  placeholder="Please input" clearable :value="ticketWorking" disabled/>
    </div>
    <div style="width: 15%" class="mr-3">
      <p class="mb-2">Hoàn thành</p>
      <el-input   placeholder="Please input" clearable  :value="ticketDone" disabled/>
    </div>
  </div>
  <section class="study">
    <h1 style="font-size: 13px" class="bold my-3 ">Danh sách ticket</h1>
    <div class=" table-responsive w-100" >
      <table class="table table-bordered ">
        <thead class="table-secondary">
        <tr align-items class=" " style="font-size: 13px">
          <th >STT</th>
          <th >Mã ticket</th>
          <th >Phân loại</th>
          <th >Danh mục</th>
          <th >Trạng thái</th>
          <th >Thời gian tạo ticket</th>
          <th >Thời gian dự kiến gửi giải pháp</th>
        </tr>
        </thead>
        <tbody v-if="tickets.length == 0">
        <tr class="text-center">
          <td colspan="7">
            Không có dữ liệu
          </td>
        </tr>
        </tbody>
        <tbody>
        <tr v-for="(item, key) in tickets" :key="key" style="font-size: 13px">
          <td>{{ ++key }}</td>
          <td>{{ item.id }}</td>
          <td>{{ showClassify(item) }}</td>
          <td>{{ getCategory(item) }}</td>
          <td>{{ STATUS[item.status] }}</td>
          <td>{{ formatDateTimeVietnam(item.created_at)}}</td>
          <td>{{intendTime(item.is_auto,item.created_at,item) }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </section>
</div>
</template>

<script>
import {GET_DATA_TICKET_CUSTOMER} from "@/core/services/store/customer/customer.module";
import moment from "moment-timezone";

export default {
  name: "customer-care",
  data() {
    return {
      ticketWaiting: 0,
      ticketWorking: 0,
      ticketDone: 0,
      ticketCancel: 0,
      tickets: [],
      ARRAY_CATE_CHAM_SOC : {
        1: "Nghỉ liên tiếp",
        2: "Điểm thấp",
        3: "Không làm bài",
        4: "Chưa đóng tiền",
        5: "Đã đóng tiền",
        6: "Feedback thấp",
        7: "Nghỉ một buổi"
      },
      STATUS : {
        1: "Chờ xử lý",
        2: "Đã nhập giải pháp",
        3: "Đã hoàn thành",
        0: "Đã Hủy",
      },
        CHAM_SOC_HOC_VIEN : 1,
      ARRAY_CATE_CHAMSOC : {
        1: "Nghỉ liên tiếp",
        2: "Điểm thấp",
        3: "Không làm bài",
        4: "Chưa đóng tiền",
        5: "Đã đóng tiền",
        6: "Feedback thấp",
        7: "Nghỉ một buổi",
        8: "Vắng có phép",
        9: "Hướng dẫn app cho phụ huynh"
      }
    }
  },
  mounted() {
    let idUser = this.$route.params.id
    this.getDataCareCustomer(idUser)
  },
  methods: {
    getDataCareCustomer(idCustomer) {
      this.$store.dispatch(GET_DATA_TICKET_CUSTOMER,idCustomer).then((data) => {
        this.tickets = data.data.tickets;
        this.ticketWaiting = data.data.ticketWaiting;
        this.ticketWorking = data.data.ticketWorking;
        this.ticketDone = data.data.ticketDone;
        this.ticketCancel = data.data.ticketCancel;
      })
    },
    showClassify(ticket) {
      let result = '';
      switch (ticket?.is_auto) {
        case 1:
          result = 'Chăm sóc học viên';
          break;
        case 2:
        case 3:
          result = 'Yêu cầu hỗ trợ';
          break;
      }
      return result
    },
    getCategory(item) {
      if (item.is_auto === this.CHAM_SOC_HOC_VIEN) {
        return item.title;
      } else {
        return  item.category_ticket.name ? item.category_ticket.name : '';
      }
    },

    intendTime(typeTick ,time,ticket){;
        if(typeTick == this.CHAM_SOC_HOC_VIEN){
          return moment(time).add(48, 'hours').format('HH:mm DD/MM/YYYY')
        }else{
          return moment(time).add(ticket?.category_ticket?.hours, 'hours').format('HH:mm DD/MM/YYYY')
        }
    },
    formatDateTimeVietnam(date){
      return moment(date).format('HH:mm DD/MM/YYYY')
    }
  }
}
</script>

<style scoped>

</style>