<template>
<div class="">
  <section class="list_contract">
    <h1 style="font-size: 13px" class="bold my-3 ">Danh sách hợp đồng</h1>
    <div class=" table-responsive w-100" >
      <table class="table table-bordered ">
        <thead class="table-secondary">
        <tr align-items class=" " style="font-size: 13px">
          <th >Mã HĐ</th>
          <th >Chi nhánh học</th>
          <th >Giá trị hợp đồng</th>
          <th >Đã thanh toán</th>
          <th >Còn nợ</th>
          <th >Hình thức hoàn thành học phí</th>
          <th >Thời gian lên hợp đồng</th>
          <th >Hành động</th>
        </tr>
        </thead>
        <tbody v-if="listContract.length == 0">
        <tr class="text-center">
          <td  colspan="8">
            Không có dữ liệu
          </td>
        </tr>
        </tbody>
        <tbody>
        <tr  style="font-size: 13px" v-for="(contract,index) in listContract" :key="index" >
          <td >{{contract.contractCode}}</td>
          <td>{{contract.branch}}</td>
          <td >{{contract.valueBranch}}</td>
          <td >{{checkPaid(contract.paid)}}</td>
          <td >{{contract.debt}}</td>
          <td >{{contract.tuitionCompletionForm}}</td>
          <td>{{contract.timeContract}}</td>
          <td >
            <button class="btn btn-xs btn-icon mr-2 btn-outline-info"
                    title="Chi tiết hợp đồng" @click="showModalDetailContract(contract)">
              <i class="fa fa-eye"></i>
            </button>
            <button  class="btn btn-xs btn-icon mr-2 btn-outline-success" @click="getPolicy(contract.id)">
              <i class="el-icon-tickets"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </section>
  <section class="list_payment">
    <h1 style="font-size: 13px" class="bold my-3 ">Danh sách đóng tiền</h1>
    <div class=" table-responsive w-100" >
      <table class="table table-bordered ">
        <thead class="table-secondary">
        <tr align-items class=" " style="font-size: 13px">
          <th >Mã hóa đơn</th>
          <th >Mã hợp đồng</th>
          <th >Chi nhánh học</th>
          <th >Phân loại</th>
          <th >Số tiền</th>
          <th >Thời gian nạp</th>
        </tr>
        </thead>
        <tbody v-if="false">
        <tr class="text-center">
          <td colspan="4">
            Không có dữ liệu
          </td>
        </tr>
        </tbody>
        <tbody>
        <tr v-for="(item,index) in paymentList" :key="index" style="font-size: 13px" >
          <td >{{item.id}}</td>
          <td>{{item.contract_id}}</td>
          <td >{{item?.branch?.name}}</td>
          <td v-if="item.type !== 6">
            <span v-if="item.amount < 0 && item.is_refund !== 1">Rút tiền</span>
            <span v-else-if="item.amount > 0" class="badge badge-pill badge-primary">Nạp tiền</span>
            <span v-else class="badge badge-pill badge-warning cursor-pointer">Hoàn tiền</span>
          </td>
          <td v-else>
            <span class="badge badge-info">Chuyển nhượng</span>
          </td>
          <td >{{ formatMoney(item.amount) }}</td>
          <td>{{item.dated_paid}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </section>
  <section class="transfer_history">
    <h1 style="font-size: 13px" class="bold my-3 ">Lịch sử chuyển nhượng</h1>
    <div class=" table-responsive w-100" >
      <table class="table table-bordered ">
        <thead class="table-secondary">
        <tr align-items class=" " style="font-size: 13px">
          <th >Người chuyển khóa học</th>
          <th >Người nhận khóa học</th>
          <th >Khóa học chuyển nhượng</th>
          <th >Thời gian chuyển nhượng</th>
        </tr>
        </thead>
        <tbody v-if="transferHistory.length == 0">
        <tr class="text-center">
          <td colspan="4">
            Không có dữ liệu
          </td>
        </tr>
        </tbody>
        <tbody>
        <tr v-for="(item, key) in transferHistory" style="font-size: 13px" :key="key">
          <td >{{item && item.from_user && item.from_user.phone}} - {{item && item.from_user && item.from_user.name}}</td>
          <td>{{item && item.from_user && item.to_user.phone}} - {{item && item.from_user && item.to_user.name}}</td>
          <td ><div>
                <div v-if="item.courses.length > 0">
                  <span
                      class="ml-1 badge badge-pill badge-success mt-1"
                      v-for="(course, k) in item.courses"
                      :key="k"
                  >
                        {{course.name}}
                    </span>
                </div>
          </div></td>
          <td >{{item.created_at}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </section>
  <el-dialog title="Thông tin chi tiết hợp đồng" class="responsive" :visible.sync="openModalDetail">
    <modal-detail-contract :detailContract="modalContract" :flagContract="flagContract"></modal-detail-contract>
  </el-dialog>

  <el-dialog title="Xem chi tiết điều khoản & chính sách" class="responsive" :visible.sync="openModalPolicy">
     <modal-policy-course :policy="policy"></modal-policy-course>
  </el-dialog>

</div>
</template>

<script>
import moment from 'moment-timezone'
import ModalDetailContract from "@/views/pages/advise/component-detail-customer/modal-detail-contract";
import {GET_DETAIL_CUSTOMER_FINANCE, GET_POLICY} from "@/core/services/store/customer/customer.module";
import ModalPolicyCourse from "@/views/pages/advise/component-detail-customer/modal-policy-course";
export default {
  name: "finance",
  components: {ModalPolicyCourse, ModalDetailContract},
  data(){
    return{
      listContract :[],
      tuitionPaymentList: [
        'Hoàn thành toàn bộ',
        'Trả góp ngân hàng',
        'Trả góp trung tâm',
        'Đóng theo giai đoạn'
      ],
      openModalDetail: false,
      idUser : null,
      idCustomer : null,
      paymentList: [],
      transferHistory: [],
      modalContract : {},
      flagContract : false,
      openModalPolicy : false,
      policy : []

    }
  },
  mounted() {
    this.idUser = this.$route.params.id
    this.idCustomer = +this.$route.query.customer_id
    this.getDetailCustomerFinance()
  },
  methods:{
     showModalDetailContract(contract){
      this.flagContract = !this.flagContract
     this.openModalDetail = true
      this.modalContract = contract
    },
    checkPaid(value) {
       if( value[0] == '0') {
         return 'Hợp đồng chưa đóng tiền'
       }
       return value
    },
    getDetailCustomerFinance(){
      this.$store.dispatch(GET_DETAIL_CUSTOMER_FINANCE,this.idUser).then((data)=>{
        this.listContract = data.data.contracts.map((contract)=>{
          return {...contract,
            contractCode : contract.id,
            branch: contract?.branch?.name,
            valueBranch:this.formatMoney(contract.total_after_voucher) ,
            paid: this.formatMoney(contract.total_paid),
            debt: this.formatMoney(contract.total_debt),
            tuitionCompletionForm: this.tuitionPaymentList[contract.tuition_payment_id - 1],
            timeContract : moment(contract?.time_4b).format('HH:mm DD-MM-YYYY')
          }
        })
        this.paymentList = data.data.studentAmounts.map((e)=>{
          return {...e,dated_paid :moment(e.dated_paid).format('HH:mm DD-MM-YYYY')}
        })
        this.transferHistory = data?.data?.historyTransfer.map((e)=>{
          return {...e,created_at : moment(e.created_at).format('hh:mm DD-MM-YYYY')}
        })
      })
    },
    formatMoney(value){
      value = value.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
      return value
    },
    getPolicy(id){
       this.$store.dispatch(GET_POLICY,id).then((res)=>{
         _.sortBy(res.data, 'index');
         this.policy = res.data;
         this.openModalPolicy = true
       })
    }
  }
}
</script>

<style scoped>

</style>