<template>
  <div class="row">
    <div class="col-xl-12">
      <div id="panel-1" class="panel">
        <div class="panel-container show">
          <el-tabs type="border-card">
            <el-tab-pane label="Thông tin cơ bản">
              <basic-information :user="post"></basic-information>
            </el-tab-pane>
            <el-tab-pane label="Tài chính">
              <finance></finance>
            </el-tab-pane>
            <el-tab-pane label="Học tập">
              <customer-study></customer-study>
              <entry-test-result :testInputResults="testInputResults"></entry-test-result>
            </el-tab-pane>
            <el-tab-pane label="Chăm sóc khách hàng"
              ><customer-care></customer-care></el-tab-pane
            >
            <el-tab-pane label="Lịch sử khách hàng"
              ><customer-history></customer-history></el-tab-pane
            >
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasicInformation from "@/views/pages/advise/component-detail-customer/basic-information";
import Finance from "@/views/pages/advise/component-detail-customer/finance";
import { CUSTOMER_INFORMATION, GET_TEST_INPUT_USER } from "../../../core/services/store/customer/customer.module";
import CustomerStudy from "@/views/pages/advise/component-detail-customer/customer-study";
import EntryTestResult from "@/views/pages/advise/component-detail-customer/entry-test-result";
import CustomerCare from "@/views/pages/advise/component-detail-customer/customer-care";
import CustomerHistory from "@/views/pages/advise/component-detail-customer/customer-history";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "detail-customer",
  components: {CustomerHistory, CustomerCare, CustomerStudy, Finance, BasicInformation,EntryTestResult},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Danh sách khách hàng tư vấn"},
      {title: "Chi tiết khách hàng", icon: 'far fa-user'}
    ]);
    this.getInformation();
    this.getTestInputResult();
  },
  data() {
    return {
      post: {},
      idProgram : null,
      testInputResults: []
    };
  },
  methods: {
    getTestInputResult(){
        this.$store.dispatch(GET_TEST_INPUT_USER, { id: this.$route.params.id}).then((res) => {
            this.testInputResults = res.data;
        }).catch(() => {
            this.$notify({
                type: 'error',
                message: 'Lấy kết quả test đầu vào thất bại',
                title:' Thất bại'
            });
        })
    },
    getInformation() {
     this.idProgram = this.$route.params.id
      this.$store
        .dispatch(CUSTOMER_INFORMATION, this.idProgram)
        .then((data) => {
          this.post = data.data;
          console.log(data.data)
        });
    },
  },
};
</script>

<style scoped>
</style>